import { reactive } from "vue";

const theme = reactive({
  agentLogo: "spyre-group.svg",
  devLogo: "basalt-logo-full.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "d507b57b-ccaa-4410-a89e-3067321b45a3",
  videoLibraryId: "88818",
  streamCdnUrl: "https://vz-a9fe70c5-87d.b-cdn.net",
  streamApiKey: "304b4d53-1a03-4e94-87641a884ff5-269d-4b91",
  storageApiPassword: "32aa560c-a8dc-4e18-9805724149b2-ec20-4163",
  cdnUrl: "https://basalt.b-cdn.net",
  devFolder: "/basalt",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  
  // top level menu
  assetsMenuOrder: [
    'demo',
    'intro',
    'apartment-1',
    'apartment-2',
    'apartment-3',
    'amenities',
    'exterior'
  ],
  
  demoMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  introMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  apartment1MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],

  apartment2MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],

  apartment3MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  amenitiesMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  exteriorMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  demoAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },
  
  introAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  apartment1Asset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  apartment2Asset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  apartment3Asset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  amenitiesAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  exteriorAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },
});

export default { theme };