<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
    name: "AssetViewDetail",
    props: [
        'assetType',
        'viewType',
        'order'
    ],
    components: {
        DetailTopNav,
        OffCanvas
    },
    data() {
        return {
        assetTypes: null,
        assetOrder: null,
        currentAsset: null,
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
        currentIndex: null,
        assetNames: null,
        assetUrls: null,
        componentName: "AssetView",
        assetTypesReady: false
        }
    },
    created() {
        if (this.assetType === 'demo') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.demoAsset.videos
                this.assetNames = this.theme.demoAsset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.demoAsset.floorplans
                this.assetNames = this.theme.demoAsset.floorplansName
                this.assetUrls = this.theme.demoAsset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.demoAsset.panoramas
                this.assetNames = this.theme.demoAsset.panoramasName
                this.assetUrls = this.theme.demoAsset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.demoAsset.balcony
                this.assetNames = this.theme.demoAsset.balconyName
                this.assetUrls = this.theme.demoAsset.balconyUrl
            }
        } else if (this.assetType === 'intro') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.introAsset.videos
                this.assetNames = this.theme.introAsset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.introAsset.floorplans
                this.assetNames = this.theme.introAsset.floorplansName
                this.assetUrls = this.theme.introAsset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.introAsset.panoramas
                this.assetNames = this.theme.introAsset.panoramasName
                this.assetUrls = this.theme.introAsset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.introAsset.balcony
                this.assetNames = this.theme.introAsset.balconyName
                this.assetUrls = this.theme.introAsset.balconyUrl
            }
        } else if (this.assetType === 'apartment-1') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.apartment1Asset.videos
                this.assetNames = this.theme.apartment1Asset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.apartment1Asset.floorplans
                this.assetNames = this.theme.apartment1Asset.floorplansName
                this.assetUrls = this.theme.apartment1Asset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.apartment1Asset.panoramas
                this.assetNames = this.theme.apartment1Asset.panoramasName
                this.assetUrls = this.theme.apartment1Asset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.apartment1Asset.balcony
                this.assetNames = this.theme.apartment1Asset.balconyName
                this.assetUrls = this.theme.apartment1Asset.balconyUrl
            }
        } else if (this.assetType === 'apartment-2') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.apartment2Asset.videos
                this.assetNames = this.theme.apartment2Asset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.apartment2Asset.floorplans
                this.assetNames = this.theme.apartment2Asset.floorplansName
                this.assetUrls = this.theme.apartment2Asset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.apartment2Asset.panoramas
                this.assetNames = this.theme.apartment2Asset.panoramasName
                this.assetUrls = this.theme.apartment2Asset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.apartment2Asset.balcony
                this.assetNames = this.theme.apartment2Asset.balconyName
                this.assetUrls = this.theme.apartment2Asset.balconyUrl
            }
        } else if (this.assetType === 'apartment-3') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.apartment3Asset.videos
                this.assetNames = this.theme.apartment3Asset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.apartment3Asset.floorplans
                this.assetNames = this.theme.apartment3Asset.floorplansName
                this.assetUrls = this.theme.apartment3Asset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.apartment3Asset.panoramas
                this.assetNames = this.theme.apartment3Asset.panoramasName
                this.assetUrls = this.theme.apartment3Asset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.apartment3Asset.balcony
                this.assetNames = this.theme.apartment3Asset.balconyName
                this.assetUrls = this.theme.apartment3Asset.balconyUrl
            }
        } else if (this.assetType === 'amenities') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.amenitiesAsset.videos
                this.assetNames = this.theme.amenitiesAsset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.amenitiesAsset.floorplans
                this.assetNames = this.theme.amenitiesAsset.floorplansName
                this.assetUrls = this.theme.amenitiesAsset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.amenitiesAsset.panoramas
                this.assetNames = this.theme.amenitiesAsset.panoramasName
                this.assetUrls = this.theme.amenitiesAsset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.amenitiesAsset.balcony
                this.assetNames = this.theme.amenitiesAsset.balconyName
                this.assetUrls = this.theme.amenitiesAsset.balconyUrl
            }
        } else if (this.assetType === 'exterior') {
            if (this.viewType === 'videos') {
                this.assetOrder = this.theme.exteriorAsset.videos
                this.assetNames = this.theme.exteriorAsset.videosName
            } else if (this.viewType === 'floorplans') {
                this.assetOrder = this.theme.exteriorAsset.floorplans
                this.assetNames = this.theme.exteriorAsset.floorplansName
                this.assetUrls = this.theme.exteriorAsset.floorplansUrl
            } else if (this.viewType === 'virtual-tour') {
                this.assetOrder = this.theme.exteriorAsset.panoramas
                this.assetNames = this.theme.exteriorAsset.panoramasName
                this.assetUrls = this.theme.exteriorAsset.panoramasUrl
            } else if (this.viewType === 'balcony-views') {
                this.assetOrder = this.theme.exteriorAsset.balcony
                this.assetNames = this.theme.exteriorAsset.balconyName
                this.assetUrls = this.theme.exteriorAsset.balconyUrl
            }
        }

        BunnyNetService.getRenders(this.folderStructure, this.assetType)
        .then(response => {
        let j = 0;
        // Remap array

        console.log(response.data);
        
        const menuItems = response.data.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1){
                return {
                    Guid: item.Guid,
                    ObjectName: unslugify(item.ObjectName),
                    LinkName: item.ObjectName,
                    Path: item.Path,
                    IsDirectory: item.IsDirectory,
                    StorageZoneName: item.StorageZoneName,
                    title: this.assetNames[j],
                    url: this.assetUrls[j],
                    id: j++
                };
                } else {
                return {
                    Guid: item.Guid,
                    ObjectName: unslugify(item.ObjectName),
                    LinkName: item.ObjectName,
                    Path: item.Path,
                    IsDirectory: item.IsDirectory,
                    StorageZoneName: item.StorageZoneName,
                    title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
                    url: [],
                    id: j++
                };
            }
        });

        // Custom order array based on section and type
        if( this.assetOrder && this.assetOrder.length >= 1 ) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
            this.assetTypes = menuItems
        }

        this.assetTypesReady = true
        })
    },
    methods: {
        // Reorder array based on config setup
        reorder: function(data_array, real_order) {
            var i, d = {}, result = [];
            for(i=0; i<data_array.length; ++i) {
                d[data_array[i].id] = data_array[i];
            }

            for(i=0; i<real_order.length; ++i) {
                result.push(d[real_order[i]]);
            }

            return result;
        },
    },
    setup () {
        const {theme} = global

        return {
        theme
        }
    }
}
</script>